<template>
  <div id="Warehouse">
    <div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
      帮助中心
		</div>
    <div class="help-list">
      <div class="item" @click="$router.push('/HelpCom')">常见问题</div>
      <div class="item" @click="$router.push('/Article?type=user&name=用户协议')">用户协议</div>
      <div class="item" @click="$router.push('/Article?type=private1&name=隐私协议')">隐私协议</div>
      <div class="item" @click="$router.push('/Article?type=fcm&name=防沉迷公告')">防沉迷公告</div>
      <div class="item" @click="$router.push('/Article?type=fdb&name=防赌博公告')">防赌博公告</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Help',
    data() {
      return {
        
      }
    },

    mounted() {
      
    },


    methods: {
      
    },
  }
</script>

<style scoped lang="scss">
  @import "@/assets/css/base.scss";
  .recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
  .help-list{
    padding: 0 22px;
    .item{
      position: relative;
      border-bottom: 1px solid #59596B;
      height: 52px;
      @include Scenter;
      cursor: pointer;
      &::before{
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/img/icon-right.png);
        background-size: 100% 100%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
</style>
